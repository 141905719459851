import React from "react";

import logo from "./img/logo.svg";

function App() {
    return (
        <div
            className="w-100 d-flex align-items-center justify-content-center"
            style={{
                height: "100vh"
            }}
        >
            <img
                src={ logo }
                alt="Logo"
                style={{
                    maxWidth: "500px"
                }}
            />
        </div>
    );
}

export default App;
